import React, { useEffect, useRef, useState } from 'react'
import CreateProductsStyle from './CreateProductsStyle'
import Button from '../../../components/Button'
import { useNavigate } from 'react-router-dom'
import api, { customFuncsApi, customPhotoUploadApi } from '../../../api'
import { get } from 'lodash'
import { ClipLoader } from 'react-spinners'

import { BsSearch } from 'react-icons/bs'
import time from '../../../helpers/time'
import {
	ErrorModal,
	SuccessModal,
	WarningModal,
} from '../../../components/Modal'
import CreateUserModal from '../../../components/Modal/CashCreateUser'
import { useDispatch, useSelector } from 'react-redux'
import ChangeDate from './ChangeDate'
import { main } from '../../../store/slices'
import Layout from '../../../components/Layout'
import moment from 'moment'
import { roundCurrency } from '../../../utils'
import customMainApi from '../../../api/index'
import { message, Input } from 'antd'

const CreateProducts = () => {
	const { setDate } = main.actions
	const { getMe } = useSelector((state) => state.main)

	const navigate = useNavigate()
	const dispatch = useDispatch()

	const changeDateRef = useRef()
	const errorRef = useRef()
	const infoNotEndedRef = useRef()
	const successModalRef = useRef()
	const succesCreateUserRef = useRef()
	const createUserRef = useRef()

	const [customerData, setCustomerData] = useState([])
	const [garantData, setGarantData] = useState([])
	const [U_DaftarPage, setU_DaftarPage] = useState('')
	const [productNameData, setProductNameData] = useState([])
	const [seriaNumberData, setSeriaNumberData] = useState([])
	const [tableData, setTableData] = useState([])
	const [simpleMonthData, setsimpleMonthData] = useState([])
	const [vipMonthData, setVipMonthData] = useState([])

	const [price, setPrice] = useState('')
	const [color, setColor] = useState('')
	const [cloud, setCloud] = useState('')
	const [month, setMonth] = useState('')
	const [monthB2B, setMonthB2B] = useState('')
	const [customer, setCustomer] = useState('')
	const [condition, setCondition] = useState('')
	const [productName, setProductName] = useState('')
	const [seriaNumber, setSeriaNumber] = useState('')
	const [currentPrice, setCurrentPrice] = useState('')
	const [userCardCode, setUserCardCode] = useState('')
	const [seriaNumberMain, setSeriaNumberMain] = useState('')
	const [systemSerialNumber, setSystemSerialNumber] = useState('')
	const [minusedMoney, setMinusedMoney] = useState(0)
	const [productCode, setProductCode] = useState(0)
	const [dateIndex, setDateIndex] = useState(0)
	const [viewData, setViewData] = useState({})
	const [isCash, setIsCash] = useState(true)
	const [firstPayingMoney, setFirstPayingMoney] = useState(0)
	const [isCalculated, setIsCalculated] = useState(false)
	const [isMainLoading, setIsMainLoading] = useState(false)
	const [createUserLoading, setCreateUserLoading] = useState(false)
	const [userLoading, setUserLoading] = useState(false)
	const [itemLoading, setItemLoading] = useState(false)
	const [boxPlace, setBoxPlace] = useState('')

	const [comment, setComment] = useState('')
	const [owner, setOwner] = useState('Credo')
	const [userFields, setUserFields] = useState([])
	const [warehouses, setWarehouses] = useState([])
	const [toWarehouse, setToWarehouse] = useState(
		get(getMe, 'Department2.Name', '') !== 'Boshqaruv'
			? get(getMe, 'U_Warehouse', '')
			: '',
	)
	const [warehouseLoading, setWarehouseLoading] = useState(false)
	const [imageList, setImageList] = useState([
		{ file: null, path: '', imgLoading: false },
	])

	useEffect(() => {
		getSimpleMonth()
		getVIPMonth()
		userFieldsFn()
		getWarehouses()
	}, [])

	const getWarehouses = () => {
		setWarehouseLoading(true)
		customMainApi
			.get('Warehouses?$select=WarehouseCode,WarehouseName')
			.then((res) => {
				console.log(res.data)
				setWarehouses(JSON.parse(res.data).value)
			})
			.catch((err) => {
				if (err)
					errorRef.current?.open(
						JSON.stringify(err.response.data?.details?.message),
					)
			})
			.finally(() => {
				setWarehouseLoading(false)
			})
	}

	const getSimpleMonth = () => {
		api
			.get('U_INSTCONFBASIC')
			.then((res) => {
				const resData = get(JSON.parse(res.data), 'value', [])
				setsimpleMonthData(resData)
			})
			.catch((err) => {
				errorRef.current?.open(
					get(
						JSON.parse(err.response.data),
						'error.message',
						'Не удалось получить данные, попробуйте еще раз.',
					),
				)
			})
	}

	const userFieldsFn = () => {
		customMainApi
			.get('UserFieldsMD', {
				params: {
					$filter: "TableName eq 'OITM'",
					// $select: 'Name,ValidValuesMD,TableName',
				},
			})
			.then((res) => {
				setUserFields(JSON.parse(res.data).value)
			})
	}

	const getVIPMonth = () => {
		api
			.get('U_INSTCONFVIP')
			.then((res) => {
				const resData = get(JSON.parse(res.data), 'value', [])
				setVipMonthData(resData)
			})
			.catch((err) => {
				errorRef.current?.open(
					get(
						JSON.parse(err.response.data),
						'error.message',
						'Не удалось получить данные, попробуйте еще раз.',
					),
				)
			})
	}

	const searchUser = (a = '') => {
		setCustomer(a)
		setUserLoading(true)
		api
			.get(
				`BusinessPartners?$select=CardCode,CardName,Address,Phone1,U_Gender,U_PS,BPAddresses&$filter=CardType eq 'cCustomer' and Frozen eq 'tNO' and (contains(CardName, '${a}') or contains(U_PS, '${a}') or contains(Phone1, '${a}'))`,
			)
			.then((res) => {
				const resData = get(JSON.parse(res.data), 'value', [])
				setCustomerData(resData)
				if (a.includes(',')) {
					setUserCardCode(a.split(',')[1])
				}
			})
			.catch((err) => {
				if (JSON.parse(JSON.stringify(err, null, 2)).status === 401) {
					navigate('/login')
				} else {
					errorRef.current?.open(
						get(
							JSON.parse(err.response.data),
							'error.message',
							'Не удалось получить данные, попробуйте еще раз.',
						),
					)
				}
			})
			.finally(() => {
				setUserLoading(false)
			})
	}

	const searchProduct = (a = '') => {
		setProductName(a)
		setItemLoading(true)
		customFuncsApi
			.get(
				`/xsSql/getItemsByWarehouse?${get(getMe, 'Department2.Name', '') !== 'Boshqaruv' ? `WhsCode='${get(getMe, 'U_Warehouse', '')}'&` : `WhsCode='${toWarehouse}'&`}ItemName=${a}&isOnHand=true`,
			)
			.then((res) => {
				const resData = get(res.data, 'value', [])
				setProductNameData(resData)
				if (resData.length < 2) {
					const splitted = a.split(' ')
					console.log(splitted.length)
					getSameSerialNumnber(splitted[splitted.length - 1])
					setProductCode(splitted[splitted.length - 1])
				}
			})
			.catch((err) => {
				if (JSON.parse(JSON.stringify(err, null, 2)).status === 401) {
					navigate('/login')
				} else {
					errorRef.current?.open(
						get(
							JSON.parse(err.response.data),
							'error.message',
							'Не удалось получить данные, попробуйте еще раз.',
						),
					)
				}
			})
			.finally(() => {
				setItemLoading(false)
			})
	}

	const getSameSerialNumnber = (num) => {
		customFuncsApi
			.get(
				`/xsSql/getItemSerialsByWhsCode?ItemCode='${num}'${get(getMe, 'Department2.Name', '') !== 'Boshqaruv' ? `&WhsCode='${get(getMe, 'U_Warehouse', '')}'` : `&WhsCode='${toWarehouse}'`}`,
				{
					headers: {
						Prefer: 'odata.maxpagesize=1000000',
					},
				},
			)
			.then((res) => {
				const resData = get(res.data, 'value', [])
				setSeriaNumberData(resData)
			})
			.catch((err) => {
				errorRef.current?.open(
					get(
						JSON.parse(err.response.data),
						'error.message',
						'Не удалось получить серийный номер. Повторите попытку.',
					),
				)
			})
	}

	const getOtherInformations = (v) => {
		const num = Number(v.split(') ')[0]) - 1
		setSeriaNumber(v)
		const code = v.split(') ')[1] || ''
		seriaNumberData.forEach((el, index) => {
			const IntrSerial =
				get(el, 'IntrSerial', '') !== null &&
				get(el, 'IntrSerial', '').length > 6
					? get(el, 'IntrSerial', '').slice(-6)
					: get(el, 'IntrSerial', '')

			if (IntrSerial === code) {
				setSeriaNumberMain(get(el, 'IntrSerial', ''))
				setSystemSerialNumber(get(el, 'SysNumber', ''))
			}
		})
		const dataO = seriaNumberData[num]

		setColor(get(dataO, 'U_Color', ''))

		setCondition(get(dataO, 'U_Condition', ''))
		setCurrentPrice(get(dataO, 'CostTotal', ''))
	}

	const getOtherInformationsfromSerialNumber = (num) => {
		customFuncsApi
			.get(
				`/xsSql/getItemsByWarehouse?${get(getMe, 'Department2.Name', '') !== 'Boshqaruv' ? `WhsCode='${get(getMe, 'U_Warehouse', '')}'` : `WhsCode='${toWarehouse}'`}&IntrSerial='${num}'`,
			)
			.then((res) => {
				const resData = get(JSON.parse(res.data), 'value', [])
				setProductNameData(resData)
			})
			.catch((err) => {
				if (JSON.parse(JSON.stringify(err, null, 2)).status === 401) {
					navigate('/login')
				} else {
					errorRef.current?.open(
						get(
							JSON.parse(err.response.data),
							'error.message',
							'Не удалось получить данные, попробуйте еще раз.',
						),
					)
				}
			})
	}

	const addTable = () => {
		if (
			customer.length > 0 &&
			productName.length > 0 &&
			seriaNumber.length > 0 &&
			(month.length > 0 || monthB2B.length > 0)
		) {
			let monthNumber = monthB2B.length > 0 ? monthB2B : month
			monthNumber = Number(monthNumber.split(' oy')[0]) + 1

			setTableData([])

			for (let i = 0; i < monthNumber; i++) {
				let nextMonth = moment().clone().add(i, 'months')
				setTableData((pr) => [
					...pr,
					{
						date: nextMonth.format('YYYY-MM-DD'),
						price: 0,
						price2: 0,
					},
				])
			}
			setIsCalculated(true)
		} else {
			infoNotEndedRef.current?.open()
		}
	}

	useEffect(() => {
		if (month.length > 0 || monthB2B.length > 0) {
			addTable()
		}
	}, [month, monthB2B])

	const checkU_WHSCode = () => {
		if (get(getMe, 'Department2.Name', '') !== 'Boshqaruv') {
			if (get(getMe, 'U_Warehouse', null) === null) {
				alert('Foydalanuvchiga ombor biriktirilmagan')
			} else {
				createOrder()
			}
		} else {
			createOrder()
		}
	}

	const handleFileChange = (event, index) => {
		const updatedImageList = [...imageList]
		updatedImageList[index].file = event.target.files[0]
		setImageList(updatedImageList)
	}

	const uploadImg = async (index) => {
		const image = imageList[index]

		if (!image.file) {
			message.error('Фотография не выбрано')
			return
		}

		const formData = new FormData()
		formData.append('file', image.file)

		const generateRandomKey = () => {
			const characters =
				'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
			let randomKey = ''
			for (let i = 0; i < 16; i++) {
				randomKey += characters.charAt(
					Math.floor(Math.random() * characters.length),
				)
			}
			return randomKey
		}

		const randomKey = generateRandomKey()
		const title = 'YourTitle' // Replace with actual title

		formData.append('Title', title)
		formData.append('Key', randomKey)

		try {
			const updatedImageList = [...imageList]
			updatedImageList[index].imgLoading = true
			setImageList(updatedImageList)

			const res = await customPhotoUploadApi.post('assets/upload', formData, {
				headers: {
					'Content-Type': 'multipart/form-data',
				},
			})

			const { key } = res.data
			updatedImageList[index].path = key
			setImageList(updatedImageList)

			message.success('Успешно загружено!')
		} catch (err) {
			message.error('Ошибка загрузки')
		} finally {
			const updatedImageList = [...imageList]
			updatedImageList[index].imgLoading = false
			setImageList(updatedImageList)
		}
	}

	const viewImage = async (index) => {
		const image = imageList[index]

		try {
			const res = await customPhotoUploadApi.get(
				`assets/download/${image.path}`,
				{
					responseType: 'blob',
				},
			)

			const url = window.URL.createObjectURL(
				new Blob([res.data], {
					type: 'image/png',
				}),
			)

			const link = document.createElement('a')
			link.href = url
			link.setAttribute('download', 'BP.png')
			document.body.appendChild(link)
			link.click()

			document.body.removeChild(link)
			window.URL.revokeObjectURL(url)
		} catch (error) {
			message.error('Ошибка загрузки файла')
		}
	}

	const addNewRow = () => {
		setImageList([...imageList, { file: null, path: '', imgLoading: false }])
	}
	const deleteRow = (index) => {
		setImageList(imageList.filter((_, i) => i !== index))
	}

	const createOrder = () => {
		const nowDays = new Date()
		let whichType = monthB2B.length > 0 ? vipMonthData : simpleMonthData
		const photoId = imageList
			.map((image) => image.path)
			.filter(Boolean)
			.join(',')
		if (!isCash) {
			let monthNumber = monthB2B.length > 0 ? monthB2B : month || ''
			monthNumber = Number(monthNumber.split(' oy')[0])

			let protsent = monthB2B.length > 0 ? monthB2B : month || ''
			let splitProsent = protsent.split(' oy')

			protsent =
				1 +
				Number(
					whichType.find((item) => item?.U_Months == splitProsent[0])
						?.U_Percent,
				) /
					100
		}
		setIsMainLoading(true)

		const dataT = tableData.map((v) => {
			return {
				Total: v.price,
				DueDate: v.date,
			}
		})

		let body = {
			CardCode: userCardCode,
			DocDate: time.to(nowDays, 'YYYY-MM-DD'),
			U_Icloud: cloud,
			Comments: comment,
			// U_DaftarPage: U_DaftarPage,
			U_BoxPlace: boxPlace,
			SalesPersonCode: getMe.SalesPersonCode || -1,
			U_PhotoID: photoId,
			DocumentLines: [
				{
					SerialNumbers: [
						{
							InternalSerialNumber: seriaNumberMain,
							SystemSerialNumber: systemSerialNumber,
						},
					],
					Quantity: 1,
					UnitPrice: price,
					ItemCode: productCode,
					// WarehouseCode: get(getMe, 'U_Warehouse', ''),
					...(get(getMe, 'Department2.Name', '') !== 'Boshqaruv'
						? { WarehouseCode: get(getMe, 'U_Warehouse', '') }
						: { WarehouseCode: toWarehouse }),
				},
			],
		}
		if (!isCash) Object.assign(body, { DocumentInstallments: dataT })
		console.log(productCode)
		api
			.post(`Invoices`, body)
			.then((res) => {
				const resData = JSON.parse(res.data)
				setViewData({
					CardName: get(resData, 'CardName', ''),
					DocEntry: get(resData, 'DocEntry', 0),
					CardCode: get(resData, 'CardCode', 0),
					Installmnt: 1,
					ItemDescription: productName,
					Comments: comment,
				})
				successModalRef.current?.open('Заказ успешно создан')
			})
			.catch((err) => {
				setIsMainLoading(false)
				errorRef.current?.open(
					get(JSON.parse(err.response.data), 'error.message', ''),
				)
			})
	}

	const changeMainPrice = (v) => {
		let mainPrice = Number(v)
		setPrice(v)

		let dataT = mainPrice
		let A = (dataT - minusedMoney) / (Number(tableData.length) - 1)

		for (let i = 0; i < tableData.length; i++) {
			if (i !== 0) {
				let aa = [...tableData]
				aa[i].price = A
				setTableData(aa)
			} else {
				let aa = [...tableData]
				aa[0].price = minusedMoney
				setTableData(aa)
			}
		}
	}

	const changeDate = (v) => {
		let exampleDate = [...tableData]
		let day = v.slice(8, 10)

		for (let i = 0; i < exampleDate.length; i++) {
			let nextMonth = moment().clone().add(i, 'months').format('YYYY-MM-DD')
			let selectedDay = nextMonth.slice(0, 8) + day

			if (dateIndex <= i) {
				exampleDate[i].date = selectedDay
			}
		}
		setTableData(exampleDate)
		changeDateRef.current?.close()
	}

	const openChangeDate = (date, i) => {
		dispatch(setDate(date))
		changeDateRef.current?.open()
		setDateIndex(i)
	}

	const handleChangeValues = (v) => {
		let aa = [...tableData]
		let total = 0
		for (let i = 1; i < tableData.length; i++) {
			aa[i].price = v
			total += Number(v)
		}
		total += +firstPayingMoney
		setTableData(aa)
		setPrice(total)
	}

	return (
		<Layout>
			<CreateProductsStyle>
				<div className="container">
					{get(getMe, 'Department2.Name', '') === 'Boshqaruv' ? (
						<div className="mb-5">
							<div className={'flex items-center gap-1 w-full '}>
								<select
									id={'from-warehouse'}
									className={`bg-[#FAFBFC] border-[1px] ${
										!toWarehouse ? 'border-red-500' : 'border-[#DFE2E8]'
									} rounded-md p-3 text-[14px] text-[#1E1F1F] w-[315px]`}
									onChange={(e) => setToWarehouse(e.target.value)}
									value={toWarehouse}
									required
								>
									<option value={null}>Выберите склад</option>
									{warehouses.map((v, i) => (
										<option key={i} value={v.WarehouseCode}>
											{v.WarehouseName}
										</option>
									))}
								</select>
								<ClipLoader size={15} loading={warehouseLoading} />
							</div>
							{!toWarehouse && (
								<p className="text-red-500 text-sm">
									Пожалуйста, выберите склад. Это обязательное поле.
								</p>
							)}
						</div>
					) : (
						''
					)}
					<div className="grid grid-cols-1 md:grid-cols-2 gap-7 lg:grid-cols-3 items-start">
						<div className="flex gap-2">
							<div className="relative w-full">
								<input
									type="text"
									list="client"
									placeholder="Клиент"
									disabled={!toWarehouse}
									className={
										'border-[1px] border-[#DFE2E9"] p-2 rounded-md w-full'
									}
									defaultValue={customer}
									onChange={(v) => searchUser(v.target.value)}
								/>
								{userLoading && (
									<div className="absolute top-3 right-9">
										<ClipLoader size={20} color="#0A4D68" />
									</div>
								)}

								<datalist id="client">
									{customerData.map((v, i) => (
										<option
											key={i}
											value={`${get(v, 'CardName', '')},${get(
												v,
												'CardCode',
												'',
											)}`}
										/>
									))}
								</datalist>
							</div>
							<button
								className="bg-[#0A4D68] text-white px-4 rounded-md"
								onClick={() => createUserRef.current?.open()}
								disabled={!toWarehouse}
							>
								+
							</button>
						</div>

						<div className="relative">
							<input
								type="text"
								list="productName"
								disabled={!toWarehouse}
								placeholder="Товар"
								className={
									'border-[1px] border-[#DFE2E9"] p-2 rounded-md w-full'
								}
								defaultValue={productName}
								onChange={(v) => searchProduct(v.target.value)}
							/>
							{itemLoading && (
								<div className="absolute top-3 right-9">
									<ClipLoader size={20} color="#0A4D68" />
								</div>
							)}

							<datalist id="productName">
								{productNameData.map((v, i) => {
									const itemName = get(v, 'ItemName', '')
									const itemCode = get(v, 'ItemCode', '')

									const color =
										get(v, 'U_Color', '') !== ''
											? userFields
													?.find((item) => item.Name === 'Color')
													?.ValidValuesMD.find(
														(value) => get(v, 'U_Color', '') === value.Value,
													)?.Description || ''
											: ''

									const memory =
										get(v, 'U_Memory', '') !== ''
											? userFields
													?.find((item) => item.Name === 'Memory')
													?.ValidValuesMD.find(
														(value) => get(v, 'U_Memory', '') === value.Value,
													)?.Description || ''
											: ''

									const condition =
										get(v, 'U_Condition', '') !== ''
											? userFields
													?.find((item) => item.Name === 'Condition')
													?.ValidValuesMD.find(
														(value) =>
															get(v, 'U_Condition', '') === value.Value,
													)?.Description || ''
											: ''

									const formattedValue = [
										itemName,

										color,
										memory,
										condition,
										itemCode,
									]
										.filter(Boolean) // Filter out empty or falsey values
										.join(' ') // Join with ', ' only if the value is present

									return (
										<option key={i} value={formattedValue}>
											{formattedValue}
										</option>
									)
								})}
							</datalist>
						</div>
						<div className="flex gap-2">
							<input
								type="text"
								list="seriaNumber"
								placeholder="Серийный номер"
								disabled={!toWarehouse}
								className={
									'border-[1px] border-[#DFE2E9"] p-2 rounded-md w-full'
								}
								defaultValue={seriaNumber.slice(-6)}
								onChange={(v) => getOtherInformations(v.target.value)}
							/>
							<datalist id="seriaNumber">
								{seriaNumberData
									.filter((v) => get(v, 'IntrSerial', '')) // Filter out entries where IntrSerial is null or empty
									.map((v, i) => (
										<option
											key={i}
											value={`${i + 1}) ${get(v, 'IntrSerial', '').length > 6 ? get(v, 'IntrSerial', '').slice(-6) : get(v, 'IntrSerial', '')}`}
										/>
									))}
							</datalist>
							<button
								className="bg-[#0A4D68] text-white px-4 rounded-md"
								onClick={() =>
									getOtherInformationsfromSerialNumber(seriaNumber)
								}
								disabled={!toWarehouse}
							>
								<BsSearch />
							</button>
						</div>

						<input
							type="text"
							placeholder="Цвет"
							className={'border-[1px] border-[#DFE2E9"] p-2 rounded-md w-full'}
							defaultValue={
								color !== ''
									? userFields
											?.find((item) => item.Name === 'Color')
											?.ValidValuesMD.find((value) => color === value.Value)
											?.Description || ''
									: ''
							}
							// onChange={(v) => setColor(v.target.value)}
							disabled={true}
						/>

						<input
							type="text"
							placeholder="Состояние"
							className={'border-[1px] border-[#DFE2E9"] p-2 rounded-md w-full'}
							defaultValue={condition}
							disabled={true}
						/>

						<input
							type="number"
							placeholder="Текущая цена"
							className={'border-[1px] border-[#DFE2E9"] p-2 rounded-md w-full'}
							onChange={(v) => setCurrentPrice(v.target.value)}
							defaultValue={currentPrice}
							disabled={true}
						/>
						<input
							type="number"
							placeholder="Цена"
							className={'border-[1px] border-[#DFE2E9"] p-2 rounded-md w-full'}
							value={price}
							disabled={!toWarehouse}
							onChange={(v) => changeMainPrice(v.target.value)}
						/>
						<input
							placeholder="Аккаунт защиты"
							className={'border-[1px] border-[#DFE2E9"] p-2 rounded-md w-full'}
							value={cloud}
							disabled={!toWarehouse}
							onChange={(v) => setCloud(v.target.value)}
						/>
						<textarea
							placeholder="Comment"
							className={
								'border-[1px] border-[#DFE2E9"] p-2 rounded-md w-full textarea'
							}
							value={comment}
							disabled={!toWarehouse}
							onChange={(v) => setComment(v.target.value)}
						/>

						{/* <input
							type="text"
							placeholder="Номер страницы"
							disabled={!toWarehouse}
							className={'border-[1px] border-[#DFE2E9"] p-2 rounded-md w-full'}
							defaultValue={U_DaftarPage}
							onChange={(v) => setU_DaftarPage(v.target.value)}
						/> */}
						<div className="flex items-center gap-3">
							<p className="mt-1">Коробка :</p>
							<select
								className={
									'border-[1px] border-[#DFE2E9"] p-1 mt-2 rounded-md w-[230px]'
								}
								onChange={(event) => setBoxPlace(event.target.value)}
								value={boxPlace}
								placeholder="Коробка"
							>
								<option value=""> </option>

								<option value="Bor"> Bor</option>
								<option value="Yoq"> Yoq</option>
							</select>
						</div>
					</div>

					<div className="w-[600px]">
						{imageList.map((image, index) => (
							<div
								key={index}
								className="flex flex-col w-full items-start gap-3 mt-5 mb-10"
							>
								<div>
									<p className="font-bold">Фото-{index + 1}</p>
								</div>
								<div className="flex items-center gap-3">
									<Input
										type="file"
										onChange={(e) => handleFileChange(e, index)}
										className="w-full"
										disabled={!toWarehouse}
									/>
									<Button
										onClick={() => uploadImg(index)}
										isLoading={image.imgLoading}
										// disabled={!toWarehouse}
										className="h-12 w-[100px] rounded-3xl bg-[#0A4D68] text-white"
									>
										Загрузить
									</Button>
									{image.path && (
										<Button
											onClick={() => viewImage(index)}
											className="h-12 w-[100px] rounded-3xl bg-[#0A4D68] text-white"
										>
											Скачать
										</Button>
									)}
									{index !== 0 && (
										<button
											onClick={() => deleteRow(index)}
											disabled={!toWarehouse}
											className="h-[40px] w-[100px] rounded-lg p-2 bg-red-500 text-white"
										>
											Удалить
										</button>
									)}

									{index === imageList.length - 1 && (
										<Button
											onClick={addNewRow}
											// disabled={!toWarehouse}
											className="h-12 w-[100px] rounded-3xl bg-[#0A4D68] text-white"
										>
											Добавить
										</Button>
									)}
								</div>
							</div>
						))}
					</div>

					<div className="flex gap-3 mt-8 justify-end w-full">
						<Button
							btnStyle={{ backgroundColor: 'red' }}
							onClick={() => navigate('/cash-payment')}
						>
							Назад
						</Button>
						<Button
							onClick={checkU_WHSCode}
							isLoading={isMainLoading}
							disabled={!toWarehouse}
						>
							Создать
						</Button>
					</div>
				</div>
			</CreateProductsStyle>
			<>
				<CreateUserModal
					isLoading={createUserLoading}
					getRef={(r) => (createUserRef.current = r)}
					onConfirm={() =>
						succesCreateUserRef.current?.open('Клиент успешно создан')
					}
				/>
				<ErrorModal
					getRef={(r) => (errorRef.current = r)}
					onConfirm={() => {
						setIsMainLoading(false)
					}}
				/>
				<SuccessModal
					getRef={(r) => {
						succesCreateUserRef.current = r
					}}
				/>
				<SuccessModal
					getRef={(r) => {
						successModalRef.current = r
					}}
					onConfirm={() => {
						const url = `/viewItem?data=${encodeURIComponent(JSON.stringify(viewData))}&DocEntry=${viewData.DocEntry}`
						navigate(url)
					}}
				/>

				<ChangeDate
					getRef={(r) => {
						changeDateRef.current = r
					}}
					onConfirm={(v) => changeDate(v)}
				/>

				<WarningModal
					getRef={(ref) => {
						infoNotEndedRef.current = ref
					}}
				/>
			</>
		</Layout>
	)
}

export default CreateProducts
